/*jslint browser: true*/
/*global angular*/
(function () {
    'use strict';
    var module = angular.module('cyfi');

    /**
     * @ngdoc service
     * @name scenarioFactory
     *
     * @description Creates a new Scenario Value object
     * A scenario is a video seen from 1 viewpoint (actor)
     *
     * @return factory
     */
    module.service('scenarioFactory', function () {

        var Option = function (label, data) {
            this.option = label;
            this.id = 'option' + label;
            this.label = data['option' + label];
            this.feedback = data['feedback' + label];
            this.isCorrect = (data.correctOption.toLowerCase() === this.id.toLowerCase());
        };

        var Question = function (data, id) {
            var me = this;
            this.id = id;

            this.question = data.question;

            this.startTime = data.start;
            this.endTime = data.end;
            this.startOptimalTime = data.optimalStart;
            this.endOptimalTime = data.optimalEnd;

            this.options = [
                new Option('A', data),
                new Option('B', data)
            ];

            this.getScoreForOption = function (option) {
                if (option.isCorrect) {
                    return 25;
                } else {
                }
                return 0;
            };

            this.getTimingResult = function (seconds) {

                var scorePercentage = 0;
                var position;

                if (seconds >= me.startOptimalTime && seconds <= me.endOptimalTime) {
                    // perfect on the sweet spot
                    scorePercentage = 1;
                    position = 'perfect';
                } else if (seconds <= me.startOptimalTime && seconds >= me.startTime) {
                    // to early
                    scorePercentage = (seconds - me.startTime) / (me.startOptimalTime - me.startTime) ;
                    position = 'toEarly';
                } else if (seconds >= me.endOptimalTime && seconds <= me.endTime) {
                    // to late
                    scorePercentage = 1 - (seconds - me.endOptimalTime) / (me.endTime - me.endOptimalTime);
                    position = 'toLate';
                }

                console.log("get timing result for", seconds, scorePercentage);

                return {
                    score: 25 * scorePercentage,
                    position: position
                };
            };
        };

        // scenario value object
        var Scenario = function (data, id) {
            this.id = id;
            this.type = 'scenario';
            this.actorId = data.actorId;
            this.videoId = data.videoId;
            this.videoUrlCorrect = data.videoUrlCorrect;
            this.videoUrlWrong = data.videoUrlWrong;
            this.questions = [];
            this.questions.push(new Question(data.Q1, 'q1'));
            this.questions.push(new Question(data.Q2, 'q2'));

            this.isLastQuestion = function (question) {
                return (question.id === this.questions[this.questions.length - 1].id);
            };

            this.getEndFeedbackWrong = function () {
                var endQuestion = this.questions[this.questions.length - 1];
                for (var i = 0; i < endQuestion.options.length; i++) {
                    if (!endQuestion.options[i].isCorrect) {
                        return endQuestion.options[i].feedback;
                    }
                }
                return 'no feedback available';
            };
        };

        return {
            createNewInstance : function (data, id) {
                return new Scenario(data, id);
            }
        };
    });
}());
