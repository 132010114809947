/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name contentList
     *
     * @description shows a list with filtered content items
     *
     */
    angular.module('cyfi').directive('contentList', ['contentResource', 'profileService', '$state', 'videoToggleService', function (contentResource, profileService, $state, videoToggleService) {
        return {
            restrict: 'E',
            templateUrl: 'app/view/content-list.html',
            link: function($scope) {

                $scope.toggleService = videoToggleService;

//                $scope.contentList = contentResource.filteredList.concat();
                $scope.contentResource = contentResource;

                $scope.getScore = function ( actorId, videoId ) {
                    return profileService.current.score.getScoreObject(actorId, videoId).score;
                };

                $scope.itemClickHandler = function (item) {
                    if (item.type === 'video') {
                        $state.go('app.video', {id:item.id});
                    } else if (item.type === 'actor') {
                        $state.go('app.actor', {id:item.id});
                    } else {
                        throw 'unknow item type: ' + item.type;
                    }
                };

                function updateScores () {
                    var i, j;
                    for (i = 0; i <  $scope.contentList; i++) {
                        if (item.type === 'video') {
                            item.score = [];
                            //for (j = 0; j < )
                        }
                    }
                }
            }
        };
    }]);
}());
