/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name actorHeader
     *
     * @description shows choosen provile
     *
     */
    angular.module('cyfi').directive('userProfile', ['profileService', function (profileService) {
        return {
            restrict: 'E',
            templateUrl : 'app/view/user-profile.html',
            link: function($scope) {
                $scope.userProfile_clickedHandler = function () {
                    profileService.openChooseProfileDialog(true, false);
                };

                $scope.profileService = profileService;
            }
        };
    }]);

    /**
     * @ngdoc directive
     * @name userProfileChoose
     *
     * @description choose profile
     *
     */
    angular.module('cyfi').directive('userProfileChoose', ['profileService', 'ngDialog','language', function (profileService, ngDialog,language) {
        return {
            restrict: 'E',
            templateUrl : 'app/view/user-profile-choose.html',
            link: function($scope, element, iAttr) {

                $scope.userProfiles     = profileService.getList();
                var profileCheck        = $scope.ngDialogData.profileCheck;

                // Last item is anonymous profile
                $scope.anonymousProfile =  $scope.userProfiles.pop();
                console.log(profileService.current.id);
                //Labels and text
                $scope.title                = language.getText('CHOOSE_AVATAR_POPUP_TITLE');

                if (profileCheck || (profileService.current.id !=="notSelected" && profileService.current.id !=="anonymous")) {
                    $scope.subtitle_profiles    = language.getText('CHOOSE_AVATAR_POPUP_SUBTITLE');
                } else {
                    $scope.subtitle_profiles    = language.getText('CHOOSE_AVATAR_POPUP_SUBTITLE_REMINDER');
                }
                $scope.subtitle_available   = language.getText('CHOOSE_AVATAR_POPUP_SUBTITLE_FREE');
                $scope.anonymous_btn        = language.getText('AVATAR_DESCRIPTION_ANONYMOUS');

                $scope.intro =language.getText('CHOOSE_AVATAR_POPUP');

                $scope.profile_clickHandler = function (profile, doNothing) {

                    if(!doNothing){

                        profileService.switchProfile(profile.id);
                        ngDialog.closeAll();

                        //Prevent profilePopup to popup every video
                        profileService.profileAsked    = true;
                    }
                };
            }
        };
    }]);
}());
