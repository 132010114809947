/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name cyfiPlayerContent
     *
     * @description controller for the scenario player
     *
     */
    angular.module('cyfi').directive('cyfiPlayer', function () {
        return {
            restrict: 'E',
            //template: '<div cyfi-player-content video-model cyfi-player-model>test<p>test2</p></div>',
            templateUrl : 'app/view/scenario-player/cyfi-player.html',
            transclude: true
        };
    });

    /**
     * @ngdoc directive
     * @name cyfiPlayerContent
     *
     * @description controller for the scenario player
     *
     * Ik gebruik hier geen echte ng-controller omdat het dan lastig is een referentie te krijgen naar het videoModel
     *
     */
    angular.module('cyfi').directive('cyfiPlayerController', ['$timeout', 'scenariosResource', 'profileService', 'actorsResource', 'VIDEO_EVENTS', 'language', 'videosResource', '$state', 'Analytics', 'videoService', function ($timeout, scenariosResource, profileService, actorsResource, VIDEO_EVENTS, language,  videosResource, $state, Analytics, videoService) {
        return {
            restrict: 'A',
            require : ['videoModel'],
            link : function ($scope, $element, $attrs, models) {


                var videoModel = models[0];
                var currentScenario = scenariosResource.getCurrentScenario();
                var currentVideo    = videosResource.getCurrentVideo();
                var scoreObject     = profileService.current.score.createNewScoreObject(currentScenario.videoId, currentScenario.actorId);
                var answeredQuestionIds = {};
                var switchVideoToCorrectUrl = false; // true when second question is correct answered
                var timingResult; // result after player clicks on 'fix me button'

                Analytics.trackPageView('open-scenario-' + currentScenario.videoId + '-' + currentScenario.actorId);

                $scope.isFixItButtonActive = false;

                gotoState('intro');

                $scope.question;
                $scope.feedback;
                $scope.endFeedback;
                $scope.activeActor    = actorsResource.getById(currentScenario.actorId);
                (function createActors () {
                    var i, actors, actor;
                    actors = actorsResource.getActorsByIds(currentVideo.actors);

                    $scope.actors = [];
                    for (var i = 0; i < actors.length; i++) {
                        actor = actors[i].clone();
                        if (actor.id === $scope.activeActor.id) {
                            actor.score = scoreObject;
                        } else {
                            actor.score = profileService.current.score.getScoreObject(actor.id, currentVideo.id);
                        }

                        $scope.actors.push(actor);
                    }
                }());
                $scope.latestScore  = 0;

                $scope.fixBtnClickHandler = function () {
                    if ($scope.isFixItButtonActive) {
                        var question = findQuestion($scope.currentTime);
                        if (question) {
                            $scope.question = question;
                            answeredQuestionIds[question.id] = true;

                            videoModel.pauseVideo();

                            gotoState('question');

                        } else {
                            throw 'Something went wrong, "fix it" button should not be active.';
                        }
                    }
                    $scope.isFixItButtonActive = false;
                };

                $scope.questionOptionClickHandler = function (option) {
                    timingResult = $scope.question.getTimingResult($scope.currentTime);
                    scoreObject.addScore(timingResult.score);
                    scoreObject.addScore($scope.question.getScoreForOption(option));
                    $scope.latestScore = scoreObject.score - $scope.latestScore;

                    Analytics.trackPageView('question-scenario-' + currentScenario.videoId + '-' + currentScenario.actorId + '-' + $scope.question.id + '-answer-' + option.option);

                    if (currentScenario.isLastQuestion($scope.question)) {
                        $scope.endFeedback = option.feedback;
                        if (option.isCorrect) {
                            switchVideoToCorrectUrl = true;
                        }

                        switch (timingResult.position) {
                            case 'perfect':
                                $scope.feedback =language.getText('FIX_IT_PERFECT', {actor : $scope.activeActor.name});
                                break;
                            case 'toLate':
                                $scope.feedback =language.getText('FIX_IT_TO_LATE', {actor : $scope.activeActor.name});
                                break;
                            case 'toEarly':
                                $scope.feedback =language.getText('FIX_IT_TO_EARLY', {actor : $scope.activeActor.name});
                                break;
                            default:
                                throw 'Unknown score position: ' + scoreObject.position;
                        }
                    } else {
                        $scope.feedback = option.feedback;
                    }

                    $scope.goToNextState();
                };

                function gotoState (newState) {
                    videoModel.setEnabled(newState === 'video');
                    $scope.state = newState;
                    videoService.currentState = newState;
                }

                $scope.goToNextState = function () {

                    var newState;
                    switch ($scope.state) {
                        case 'intro':
                            newState = 'video';
                            break;
                        case 'question':
                            newState = 'feedback';
                        break;
                        case 'feedback':
                            newState = 'video';
                        break;
                    }

                    gotoState(newState);


                    if (newState === 'video') {
                        if (switchVideoToCorrectUrl)  {
                            switchVideoToCorrectUrl = false;
                            var newUrl = currentScenario.videoUrlCorrect + '#t=' + videoModel.getCurrentTime();
                            videoModel.setSource(newUrl);
                        }
                        videoModel.playVideo();
                    }
                };

                $scope.startScenario = function (actorId) {
                    $state.go('app.scenario', {actorId:actorId, videoId:currentVideo.id}, {reload: true});
                };

                // always starts with the wrong video
                $timeout(function () {
                    videoModel.setSource(currentScenario.videoUrlWrong);
                });

                function findQuestion (time) {
                    var result = null;
                    for (var i = 0; i < currentScenario.questions.length; i++) {
                        var question = currentScenario.questions[i];
                        if (time > question.startTime && time < question.endTime) {
                            result = question;
                        }
                    }
                    return result;
                }

                function showEndFeedback () {

                    Analytics.trackPageView('end-video-' + currentScenario.videoId + '-' + currentScenario.actorId);

                    if (!$scope.endFeedback) {
                        // player didn't clicked on (second) fix me button, show wrong end feedback
                        $scope.endFeedback  = currentScenario.getEndFeedbackWrong();
                    }

                    // save score for current scenario
                    profileService.current.score.updateScores(scoreObject);

                    gotoState('endFeedback');

                    $scope.$digest();
                }

                // watch time to activate 'fix me' button
                $scope.$watch('currentTime', function (currentTime) {
                    if (currentTime) {

                        var question = findQuestion(currentTime);
                        if (question && !answeredQuestionIds[question.id]) {
                            $scope.isFixItButtonActive = true;
                        } else {
                            $scope.isFixItButtonActive = false;
                        }
                    }
                });

                videoModel.$on(VIDEO_EVENTS.VIDEO_ENDED,showEndFeedback);

                if (!profileService.profileAsked) {
                    profileService.openChooseProfileDialog(false,true);
                }

            }
        };

    }]);
}());
