/*jslint browser: true*/
/*global angular, console */
(function () {
    'use strict';

    var app = angular.module('cyfi');

    function getPathToSocialImg ($state, $injector) {

        var result;

        switch ($state.current.name) {
            /*
            case 'app.video':
                var videosResource = $injector.get('videosResource');
                result = 'assets/img/videos/' + videosResource.getCurrentVideo().id + '/thumb_video.png';
                break;
            case 'app.actor':
                var actorsResource = $injector.get('actorsResource');
                result = 'assets/img/actors/' + actorsResource.getCurrentActor().id + '/block-bg.png';
                break;
            */
            default:
                result = 'assets/img/can-you-fix-it-social.jpg';
        }

        return result;

    }

    function getSocialDescription ($state, $injector) {

        var result;

        switch ($state.current.name) {
            /*
            case 'app.video':
                var videosResource = $injector.get('videosResource');
                result = $injector.get('language').getText('SOCIAL_VIDEO_DESCRIPTION', {video_name : videosResource.getCurrentVideo().name});
                break;
            case 'app.actor':
                var actorsResource = $injector.get('actorsResource');
                result = $injector.get('language').getText('SOCIAL_ACTOR_DESCRIPTION', {actor_name : actorsResource.getCurrentActor().name});
                break;
                */
            default:
                result = $injector.get('language').getText('SOCIAL_HOME_DESCRIPTION');
        }

        return result;
    }



    /**
     * @ngdoc directive
     * @name socialMedia
     *
     * @description shows the social media buttons to share this application
     *
     */
    app.directive('socialMedia', ['$state', 'language', 'actorsResource', 'videosResource', 'getAbsoluteUrl', '$injector', function ($state, language, actorsResource, videosResource, getAbsoluteUrl,$injector) {
        return {
            restrict: 'E',
            template: '<div class="social-media-container">' +
                '<div class="social-media-icon icon-mail" ng-click="mailSharing()"></div>' +
                '<div class="social-media-icon icon-facebook" ng-click="facebookSharing()"></div>' +
                '<div class="social-media-icon icon-twitter" ng-click="twitterSharing()"></div>' +
                '<div class="social-media-icon icon-linkedin" ng-click="linkedSharing()"></div>' +
                '<div class="social-media-icon icon-pinterest" ng-click="pinterestSharing()"></div>' +
            '</div>',
            controller: ['$scope', '$window', function($scope, $window){
/*
                SOCIAL_TWITTER_HOME
                SOCIAL_TWITTER_VIDEO
                SOCIAL_TWITTER_ACTOR
                SOCIAL_FACEBOOK_HOME
                SOCIAL_FACEBOOK_VIDEO
                SOCIAL_FACEBOOK_ACTOR
                SOCIAL_HOME_DESCRIPTION
                SOCIAL_VIDEO_DESCRIPTION
                SOCIAL_ACTOR_DESCRIPTION
                SOCIAL_LINKEDIN_HOME
                SOCIAL_LINKEDIN_VIDEO
                SOCIAL_LINKEDIN_ACTOR
                SOCIAL_PINTEREST_HOME
                SOCIAL_PINTEREST_VIDEO
                SOCIAL_PINTEREST_ACTOR
*/
                function openShareWindow (url) {
                    $window.open(url,'sharer','toolbar=0,status=0,width=626,height=436');
                }

                function getUrl () {
                    //http://stackoverflow.com/questions/9610156/using-hash-tags-with-facebook-share-possible
                    return encodeURI($window.location.href).replace('#', '%23');
                }

                function getTitle (platform, url) {
                    var translateKey = 'SOCIAL_';
                    var interpolateParams = {url : url};

                    switch ($state.current.name) {
                        case 'app.video':
                            translateKey += platform + '_VIDEO';
                            interpolateParams.video_name = videosResource.getCurrentVideo().name;
                            break;
                        case 'app.actor':
                            translateKey += platform + '_ACTOR';
                            interpolateParams.actor_name = actorsResource.getCurrentActor().name;
                            break;
                        default:
                            translateKey += platform + '_HOME';
                    }

                    return encodeURI(language.getText(translateKey, interpolateParams));
                }

                function substituteUrl (url, replacements) {
                    // replace all of the parameters [] in the url string
                    for (var i = 0; i < replacements.length; i++) {
                        url = url.replace(new RegExp('\\['+i+'\\]', 'g'), replacements[i]);
                    }

                    return url;
                }

                // urls social media:
                // http://petragregorova.com/articles/social-share-buttons-with-custom-icons/
                // http://stackoverflow.com/questions/12448134/social-share-links-with-custom-icons

                $scope.facebookSharing = function () {
                    //http://www.facebook.com/share.php?u=[URL]&title=[TITLE]
                    var url = getUrl();
                    openShareWindow(substituteUrl('http://www.facebook.com/share.php?u=[0]&title=[1]', [url, getTitle('FACEBOOK',url)]));
                };
                $scope.mailSharing = function () {
                    openShareWindow ('http://api.addthis.com/oexchange/0.8/forward/email/offer?url=http%3A%2F%2Fcanyoufixit.sense.info/');
                };
                $scope.twitterSharing = function () {
                    var url = getUrl();
                    openShareWindow(substituteUrl('http://twitter.com/intent/tweet?status=[1]+[0]', [getTitle('TWITTER', url),url]));
                };
                $scope.linkedSharing = function () {
                    var url = getUrl();
                    openShareWindow(substituteUrl('http://www.linkedin.com/shareArticle?mini=true&url=[0]&title=[1]', [url, getTitle('LINKEDIN',url)]));
                };
                $scope.pinterestSharing = function () {
                    var url = getUrl();
                    openShareWindow(substituteUrl('http://pinterest.com/pin/create/bookmarklet/?media=[0]&url=[1]&is_video=false&description=[2]', [getAbsoluteUrl(getPathToSocialImg($state), $injector) ,url, getTitle('PINTEREST', url)]));
                };
            }]
        };

    }]);

    /*
    // update meta data based on current url
    app.run(['$rootScope', '$document', '$state', 'getAbsoluteUrl', '$injector',
        function ($rootScope, $document, $state, getAbsoluteUrl, $injector) {
            var head, ogDescription, ogImage;
            head = angular.element($document[0].head);
            ogImage = angular.element('<meta property="og:image" content="">');
            ogDescription = angular.element('<meta property="og:description" content="">');

            head.append(ogDescription);
            head.append(ogImage);

            $rootScope.$on('$stateChangeSuccess', function () {

                var description = getSocialDescription($state, $injector);
                var imgUrl = getAbsoluteUrl(getPathToSocialImg($state, $injector));

                ogImage.attr('content', imgUrl);
                ogDescription.attr('content', description);

            });
        }]);
        */
}());