/*jslint browser: true*/
/*global angular */
(function () {
    'use strict';
    var module = angular.module('cyfi');



    /**
     * @ngdoc service
     * @name videoService
     *
     * @description list of all available tags
     *
     * @return tagsResource
     */
    module.service('videoService', [function () {
        var service = {};

        service.currentState = null;

        return service;
    }]);

    module.service('videoToggleService', ['$rootScope', '$state', function($rootScope, $state) {

        function needToShowVideos() {
            return !window.isMobile || $state.current.name != 'app.scenario';
        }

        var service = {};
        service.showVideos = needToShowVideos();

        $rootScope.$on('$stateChangeSuccess', function() {
            console.log($state.current.name );
            service.showVideos = needToShowVideos();
        });

        return service;
    }])

}());
